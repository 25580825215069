import { Heading } from '@fis/ui-heading';
import { styled } from '@fis/util-theme';
import { UiHeroBackground } from './ui-hero-background';

const HeroTitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$7',
  width: '100%',
  maxWidth: '67.5rem',
  margin: '0 auto',
  padding: '2rem 0',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '20rem',
});

const StyledHeading = styled(Heading, {
  fontSize: '$100',
  maxWidth: '30rem',
  textAlign: 'center',
});

const StyledSubheading = styled(Heading, {
  maxWidth: '30rem',
  textAlign: 'center',
});

type HeroTitleProps = {
  titleText: string;
  subtitleText?: string;
};

export const UiHeroTitle = ({ titleText, subtitleText }: HeroTitleProps) => {
  return (
    <HeroTitleContainer>
      <UiHeroBackground />
      <StyledHeading styling="xxxl">{titleText}</StyledHeading>
      {subtitleText ? (
        <StyledSubheading styling="lg">{subtitleText}</StyledSubheading>
      ) : null}
    </HeroTitleContainer>
  );
};
