import { useEffect } from 'react';
import { signIn, signOut, useSession } from 'next-auth/client';
import { configureScope } from '@sentry/nextjs';

import { client } from '@fis/data-graphql';

export interface AuthHandlerProps {
  children?: JSX.Element;
}

export const AuthHandler = ({ children }: AuthHandlerProps) => {
  const [session, loading] = useSession();

  useEffect(() => {
    /*
     * NOTE: this check forces any session lacking `permissions` to reset
     * itself, which should help ensure that all sessions have moved from
     * `roles` to `permissions`.
     */
    if (session && !session.permissions) {
      console.warn(
        'Session lacks permissions, forcing sign out.',
        JSON.stringify(session)
      );
      signOut();
      return null;
    } else if (!session && !loading) {
      (async () => {
        await client.resetStore();
        signIn('portal');
      })();
    } else if (session && !loading) {
      const sub = session.sub as string;
      const email = session.email as string;
      const permissions = session.permissions as string[];

      // Sentry
      configureScope((scope) => {
        scope.setUser({
          id: sub,
          permissions,
        });
      });
    }
  }, [session, loading]);

  return session && !loading ? children : null;
};

export default AuthHandler;
