const defaultOptions = {
  // enabled: process.env.CONTEXT !== 'development',
  environment: process.env.CONTEXT,
  debug: process.env.SENTRY_DEBUG == 'true',
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
};

export const tracesSampleRateForEnv = () => {
  switch (process.env.CONTEXT) {
    case 'development':
    case 'deploy-preview':
    case 'deploy_preview':
      return 1.0; // 100% of activity in test/dev
    case 'production':
    default:
      // sentry docs suggest 0.2 in production but that it will need tuned over time
      return 0.2;
  }
};

export const initializeSentry = (sentryPackage, opts = {}) => {
  const navigator = (global || window)['navigator']; // next means sometimes we're browser context, sometimes node context
  const isCypress = 'Cypress' in (global || window);
  if (navigator) {
    // if browser, more checks
    const isDatadog =
      navigator &&
      navigator.userAgent &&
      navigator.userAgent.includes('DatadogSynthetics');
    isDatadog || isCypress
      ? console.warn('Datadog and/or Cypress detected')
      : sentryPackage.init({ ...defaultOptions, ...opts });
  } else {
    // default behavior
    sentryPackage.init({ ...defaultOptions, ...opts });
  }
};

export default initializeSentry;
