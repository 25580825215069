import {
  ApolloClient,
  from,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';

export { gql } from '@apollo/client';

// TODO: Look into persisted queries
// TODO: Cache redirects using field policy
// https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions

// apollo-link-serialize:
// An Apollo Link that ensures requests are sent in the order they were received

// apollo-upload-client:
// A terminating Apollo Link for Apollo Client that fetches a GraphQL multipart request if the
// GraphQL variables contain files (by default FileList, File, Blob, or ReactNativeFile
// instances), or else fetches a regular GraphQL POST or GET request
// (depending on the config and GraphQL operation).
// https://github.com/jaydenseric/apollo-upload-client

export const cache = new InMemoryCache({
  dataIdFromObject: (object) =>
    `${object.__typename}:${
      object.uuid || object.id || defaultDataIdFromObject(object)
    }`,
  typePolicies: {
    Account: {
      keyFields: ['url'],
    },
    AvailableLiveEvents: {
      fields: {
        liveEvents: {
          merge: (_existing, incoming) => incoming, // No need to merge, just replace
        },
      },
      keyFields: [], // Singleton
    },
    ContinueLearning: {
      keyFields: [], // Singleton
    },
    Course: {
      keyFields: ['courseUrl'],
    },
    CourseEdge: {
      keyFields: ['cursor'],
    },
    Document: {
      keyFields: ['assignedAt', 'url'],
    },
    Educator: {
      keyFields: ['uuid'], // Singleton
    },
    Link: {
      keyFields: ['source', 'name'],
    },
    LiveEventFilter: {
      keyFields: ['className'],
      fields: {
        options: {
          merge: (_existing, incoming) => incoming, // No need to merge, just replace
        },
      },
    },
    LiveEventFilters: {
      keyFields: [], // Singleton
    },
    MilestoneSchedule: {
      keyFields: [], // Singleton
    },
    MilestoneTimelineNode: {
      keyFields: ['milestone', ['uuid']],
    },
  },
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
  credentials: 'include',
  fetch: (uri, options) => {
    const { operationName } = JSON.parse(options.body as string);
    const nonProductionAuthHeaders = {};

    if (process.env.NEXT_PUBLIC_ALLOW_PUBLIC_TOKEN === 'true') {
      const token = Cookies.get('fis-token');
      const impersonatedUuid = Cookies.get('fis-impersonation-uuid');

      if (token) {
        nonProductionAuthHeaders['Authorization'] = `Bearer ${token}`;
      }

      if (impersonatedUuid) {
        nonProductionAuthHeaders['fis-impersonation-uuid'] = impersonatedUuid;
      }
    }

    Sentry.addBreadcrumb({
      category: 'graphql-request',
      message: operationName,
    });

    return fetch(uri, {
      ...options,
      headers: {
        ...options.headers,
        ...nonProductionAuthHeaders,
        operationName,
      },
    });
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (!graphQLErrors && !networkError) return forward(operation);

    if (graphQLErrors) {
      const ERROR_TEXT = 'GraphQL error delivered from API';
      // eslint-disable-next-line no-console
      console.error({
        message: ERROR_TEXT,
        operation: operation.operationName,
        graphQLErrors,
      });
      Sentry.withScope((scope) => {
        scope.setExtra('operation', operation.operationName);
        scope.setExtra(
          'graphQLErrors',
          graphQLErrors.map((error) => ({
            error: error.message,
            path: (error.path || []).join('.'),
          }))
        );
        graphQLErrors.forEach((error) => {
          scope.setTag('error-message', error.message);
          scope.setTag('path', (error.path || []).join('.'));
          scope.setTag('operation', operation.operationName);
          scope.setTag('error-kind', 'graphql-error');
          Sentry.captureException(
            new Error(`[GraphQL error] ${error.message}`)
          );
        });
      });
    }

    if (networkError) {
      const networkMsg = `[Network error]: ${networkError.message}`;

      // eslint-disable-next-line no-console
      console.error({
        message: networkMsg,
        operation: operation.operationName,
      });
      Sentry.withScope((scope) => {
        scope.setExtra('operation', operation.operationName);
        scope.setExtra('variables', operation.variables);
        scope.setTag('operation', operation.operationName);
        scope.setTag('error-message', networkError.message);
        scope.setTag('error-kind', 'network-error');
        Sentry.captureException(networkError);
      });
    }

    return forward(operation);
  }
);

const sentryLink = new SentryLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
  setTransaction: true,
  setFingerprint: true,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: false,
    includeFetchResult: false,
    includeError: true,
  },
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 3,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    retryIf: (error, _operation) => !!error && error.statusCode !== 401,
  },
});

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  name: process.env.PROJECT_NAME,
  link: from([sentryLink, retryLink, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
