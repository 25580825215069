import { useEffect } from 'react';

import TagManager from 'react-gtm-module';
import { IdProvider } from '@radix-ui/react-id';
import { FormProvider, useForm } from 'react-hook-form';

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import type { AppProps /* , AppContext */ } from 'next/app';

import { shouldPolyfill as shouldPolyfillIntlRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';

import { Provider as AuthProvider } from 'next-auth/client';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { client, ApolloProvider } from '@fis/data-graphql';
import AuthHandler from '@fis/feature-auth-handler';
import { stitchesGlobal } from '@fis/util-theme';
import { UiHeroTitle } from '@fis/ui-hero-title';

import {
  initializeSentry,
  tracesSampleRateForEnv,
} from '../../../../sentry.config';

import '@flatiron-school/ui/dist/normalize.css'; // TODO: Add Locally

/* --------------------------------------------------------
 * Sentry Init for Browser
 * ------------------------------------------------------*/

initializeSentry(Sentry, {
  integrations: [new BrowserTracing()],
  tracesSampleRate: tracesSampleRateForEnv(),
});

/* --------------------------------------------------------
 * Polyfills for Intl API
 * ------------------------------------------------------*/

(async () => {
  const polyfills = [];
  const data = [];

  if (!shouldPolyfillIntlRelativeTimeFormat()) {
    polyfills.push(import('@formatjs/intl-relativetimeformat/polyfill'));

    data.push(import('@formatjs/intl-relativetimeformat/locale-data/en'));
  }

  if (!shouldPolyfillIntlDateTimeFormat()) {
    polyfills.push(import('@formatjs/intl-datetimeformat/polyfill'));

    data.push(import('@formatjs/intl-datetimeformat/add-golden-tz'));
    data.push(import('@formatjs/intl-datetimeformat/locale-data/en'));
  }

  if (polyfills.length !== 0) {
    await Promise.all(polyfills);
    await Promise.all(data);
  }
})();

/* --------------------------------------------------------
 * Global Styles
 * ------------------------------------------------------*/

const globalStyles = stitchesGlobal({
  html: {
    scrollBehavior: 'smooth',
  },
  body: {
    fontFamily: '$firaSans',
    fontWeight: '$light',
    margin: 0,
    backgroundColor: '$gray900',
    color: '$white500',

    '&.lightMode': {
      backgroundColor: 'white',
    },
  },
  a: {
    textDecoration: 'none',
  },
  '.ql-indent-1': {
    marginLeft: '1em',
  },
  '.ql-indent-2': {
    marginLeft: '2em',
  },
  '.ql-indent-3': {
    marginLeft: '3em',
  },
  '.ql-indent-4': {
    marginLeft: '4em',
  },
  '.ql-indent-5': {
    marginLeft: '5em',
  },
});

/* --------------------------------------------------------
 * App
 * ------------------------------------------------------*/

function App({ Component, pageProps }: AppProps): JSX.Element {
  const methods = useForm();

  const underMaintenance = process.env.NEXT_PUBLIC_UNDER_MAINTENANCE === 'true';

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH,
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
    });
  }, []);

  globalStyles();

  return (
    <IdProvider>
      <AuthProvider
        session={pageProps.session}
        options={{
          baseUrl: process.env.ROOT_URL,
          clientMaxAge: 0, // Disabled (always use cache value)
        }}
      >
        <ApolloProvider client={client}>
          <FormProvider {...methods}>
            <ToastContainer style={{ width: '40%' }} />
            <AuthHandler>
              {underMaintenance ? (
                <UiHeroTitle
                  titleText="Under maintenance..."
                  subtitleText="We are conducting scheduled maintenance. We'll be back up and running soon!"
                />
              ) : (
                <Component {...pageProps} />
              )}
            </AuthHandler>
          </FormProvider>
        </ApolloProvider>
      </AuthProvider>
    </IdProvider>
  );
}

export default App;
